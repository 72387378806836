import React from "react";
import logo from "./logo.svg";
import "./App.css";
import packageJson from "../package.json";
import StickyFooter from "./StickyFooter";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      version: packageJson.version
    };
  }
  render() {
    let version = this.state.version;
    return (
      <div>
        <div className="App">
          <head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
          </head>
          <div className="version">Version {version}</div>
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p className="fadingText">Welcome to the home of Kevin Loukusa Web Design!</p>
            <p>Are you interested in a responsive, mobile-first custom website? Click <a className="phoneNumber"href="tel:1-763-438-0425">here</a> to give me a call!</p>
            <p className="loading">Website coming soon</p>
            <p>
              Check out my experience on{" "}
              <a
                className="App-link"
                href="https://www.linkedin.com/in/kevinvloukusa/"
              >
                LinkedIn
              </a>
            </p>
          </header>
        </div>
        <StickyFooter />
      </div>
    );
  }
}

export default App;
